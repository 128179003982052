import React from 'react'

// Images
import check from 'img/check.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import Title from 'components/elements/Title'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface ImageWithBlockProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const ImageWrapper = styled.div`
  position: relative;
  height: 385px;
  width: 100%;
  border-radius: 25px;
  margin-bottom: 180px;

  & img {
    height: 385px;
    border-radius: 25px;
  }

  @media screen and (max-width: 991px) {
    height: fit-content;
    margin-bottom: 50px;
  }
`

const Block = styled.div<{ left: boolean }>`
  background-color: ${({ theme }) => theme.color.light};
  position: absolute;
  width: 500px;

  padding: 40px 40px 30px 40px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 25px;

  ${({ left }) =>
    left
      ? css`
          bottom: -180px;
          left: -200px;
        `
      : css`
          bottom: -180px;
          right: -290px;
        `}

  & h2 {
    font-size: 22px;
    line-height: 26px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & ul {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  & li {
    list-style: none;
    line-height: 40px;
    font-size: 18px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      background-image: url(${check});
      background-size: contain;
      background-repeat: no-repeat;
      height: 15px;
      width: 20px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      left: -30px;
    }
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    left: 0;
    padding: 20px 20px 20px 20px;
    position: relative;
    margin-top: -100px;

    ${({ left }) =>
      left
        ? css`
            bottom: 0;
          `
        : css`
            bottom: 0;
          `}

    & li {
      line-height: 24px;
      padding: 6px 0;
    }
  }
`

const ImageWithBlock: React.FC<ImageWithBlockProps> = ({ fields }) => (
  <section>
    <div className="container py-lg-5 py-3">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <Title>
            <ParseContent content={fields.backgroundtext} />
          </Title>
        </div>
        <div className="col-lg-3" />
      </div>
      {fields.position === 'left' && (
        <div className="row justify-content-center">
          <div className="col-lg-1" />
          <div className="col-lg-6">
            <ImageWrapper>
              <Plaatjie image={fields.image} alt="" />
              <Block left={false}>
                <ParseContent content={fields.description} />
              </Block>
            </ImageWrapper>
          </div>
          <div className="col-lg-2" />
        </div>
      )}
      {fields.position === 'right' && (
        <div className="row justify-content-center">
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <ImageWrapper>
              <Plaatjie image={fields.image} alt="" />
              <Block left>
                <ParseContent content={fields.description} />
              </Block>
            </ImageWrapper>
          </div>
        </div>
      )}
    </div>
  </section>
)

export default ImageWithBlock
