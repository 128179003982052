import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'
import Title from 'components/elements/Title'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { motion } from 'framer-motion'

interface MethodProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const BlockTitle = styled(ParseContent)`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-size: 20px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  transition: all 0.3s ease;
`

const Block = styled(motion.div)<{ active: boolean }>`
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 40px 30px;
  border-radius: 25px;
  transition: all 0.3s ease;
  position: relative;

  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme.color.primary};

          ${BlockTitle} {
            color: ${theme.color.light};
          }
        `
      : css`
          background-color: ${theme.color.light};

          ${BlockTitle} {
            color: ${theme.color.dark};
          }
        `}

  @media screen and (max-width: 991px) {
    padding: 20px 30px;
    margin-bottom: 10px;
    margin-top: 5px;

    &:before {
      content: none;
    }
  }
`

const Icon = styled(Plaatjie)`
  margin-bottom: 15px;
  width: 35px;
  height: 35px;
  object-position: contain;
`

const Method: React.FC<MethodProps> = ({ fields }) => {
  const [current, setCurrent] = React.useState(1)

  return (
    <section>
      <div className="container py-lg-5 py-3">
        <Title>werkwijze</Title>
        <div className="row pb-4">
          {fields.methoditems?.map((slideritem, index: number) => (
            <div className="col-sm-6 col-xl-3 mb-4 mb-xl-0">
              <Block
                onHoverStart={() => setCurrent(index)}
                onTap={() => setCurrent(index)}
                active={current === index}
              >
                <Icon image={slideritem?.icon} alt="icon" />
                <BlockTitle content={slideritem?.title} />
              </Block>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Method
