import React from 'react'

// Components
import CallToAction from 'components/flex/Components/CallToAction'
import BigCallToAction from 'components/flex/Components/BigCallToAction'
import PartnerBanner from 'components/flex/Components/PartnerBanner'
import Slider from 'components/flex/Components/Slider'
import Expertises from 'components/flex/Components/Expertises'
import MethodWithText from 'components/flex/Components/MethodWithText'
import Method from 'components/flex/Components/Method'
import Vision from 'components/flex/Components/VisionBlocks'
import ImageWithBlock from 'components/flex/Components/ImageWithBlock'
import MethodWithoutTitle from 'components/flex/Components/MethodWithoutTitle'
import ImageWithBlockWithoutTitle from 'components/flex/Components/ImageWithBlockWithoutTitle'
import TeamPreview from 'components/flex/Components/TeamPreview'
import Team from 'components/flex/Components/Team'
import VisionBlockSmall from 'components/flex/Components/VisionBlocksSmall'

interface ComponentsShellProps {
  fields: any
  location?: any
  pageContext?: any
}

interface ComponentsProps {
  [key: string]: any
}

const ComponentsShell: React.FC<ComponentsShellProps> = ({
  fields,
  location = {},
  pageContext,
}) => {
  const types: ComponentsProps = {
    cta: CallToAction,
    big_cta: BigCallToAction,
    partner: PartnerBanner,
    slider: Slider,
    expertise: Expertises,
    method_with_text: MethodWithText,
    method: Method,
    vision: Vision,
    visionsmall: VisionBlockSmall,
    method_without_title: MethodWithoutTitle,
    image_block: ImageWithBlock,
    image_block_without_title: ImageWithBlockWithoutTitle,
    team_preview: TeamPreview,
    team: Team,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default ComponentsShell
